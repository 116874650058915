<template>
  <div>
    <div class="opt-bar flex">
    </div>
  </div>
</template>


<script>
export default {
  data(){
    return {

    } //--return
  },//--data
  created(){

  },
  methods: {

  }//-methods
};
</script>

<style scoped lang="scss">

</style>